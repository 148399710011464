<template>
  <div class="box">
    <div class="loginbox">
      <div class="boxitem">
        <h2>用户登录</h2>
        <div>
          <el-input placeholder="用户名" v-model="username" class="input-with-select">
            <el-button slot="prepend" icon="el-icon-user"></el-button>
          </el-input>
        </div>
        <div>
          <el-input placeholder="密码" type="password" v-model="password" class="input-with-select">
            <el-button slot="prepend" icon="el-icon-lock"></el-button>
          </el-input>
        </div>
        <div class="btn">
          <el-button type="primary" @click="gologin">登录</el-button>
        </div>
      </div>
      <div class="boxitem"></div>
    </div>
    <div class="foot">
      <a class="biean" href="http://beian.miit.gov.cn" target="http://beian.miit.gov.cn">浙ICP备2021020869号-2</a>
    </div>
  </div>
</template>

<script>
  /* 产引入jsencrypt实现数据RSA加密 */
  import JSEncrypt from 'jsencrypt' // 处理长文本数据时报错 jsencrypt.js Message too long for RSA
  import {
    getPublicKey,
    userLogin
  } from "@/api/login/login"
  export default {
    data() {
      return {
        username: "",
        password: '',
        pwd: '',
      }
    },
    beforeCreate() {
      if(sessionStorage.getItem("usernames")&&sessionStorage.getItem("passwords")){
        console.log(sessionStorage.getItem("usernames"),sessionStorage.getItem("passwords"))
        getPublicKey(sessionStorage.getItem("usernames")).then(res=>{
          if(res.data.success){
            let pk = res.data.data
            var jsencrypt = new JSEncrypt()
            jsencrypt.setPublicKey(pk)
            // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
            let result = jsencrypt.encrypt(sessionStorage.getItem("passwords"))
            userLogin(sessionStorage.getItem("usernames"),result).then(res=>{
              if (res.data.success == true) {
                this.$message({
                  message: '登录成功',
                  type: 'success'
                });
                sessionStorage.removeItem("usernames")
                sessionStorage.removeItem("passwords")
                sessionStorage.setItem("employeeCode", JSON.stringify(result))
                this.$router.push({
                  name: "industrialprofile"
                })
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'warning'
                });
              }
            })
          }
        })
      }
    },
    methods: {
      gologin() {
        const _this = this
        getPublicKey(_this.username).then(res => {
          // console.log(res)
          if (res.data.success) {
            let pk = res.data.data
            var jsencrypt = new JSEncrypt()
            jsencrypt.setPublicKey(pk)
            // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
            _this.pwd = _this.password
            let result = jsencrypt.encrypt(_this.password)
            // 输出加密结果
            _this.password = result
            // console.log(result)
            _this.sublogin()
          }
        })
      },
      sublogin() {
        var _this = this;
        userLogin(_this.username, _this.password).then(res => {
          if (res.data.success == true) {
            this.$message({
              message: '登录成功',
              type: 'success'
            });
            sessionStorage.setItem("employeeCode", JSON.stringify(_this.password))
            this.$router.push({
              name: "industrialprofile"
            })
          } else {
            _this.password = _this.pwd
            this.$message({
              message: res.data.msg,
              type: 'warning'
            });
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
</script>

<style scoped="scoped" lang="less">
  .box {
    width: 100%;
    height: 100%;
    background-image: url(../assets/loginimg/loginwai.jpg);
    background-size: 100% 100%;
    position: relative;

    .loginbox {
      width: 70%;
      height: 70%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;

      .boxitem {
        width: 50%;
        height: 100%;

        div {
          width: 100%;
          margin: 10px 0;
        }

        h2 {
          font-size: 30px;
          text-align: center;
          color: #00aaff;
          margin-bottom: 40px;
        }
      }

      .boxitem:nth-child(1) {
        padding: 11%;
        padding-top: 12%;
      }

      .boxitem/deep/.el-select {
        width: 85% !important;
      }

      .boxitem:nth-child(2) {
        background-image: url(../assets/loginimg/diannao.png);
        background-size: 70% 57%;
        background-repeat: no-repeat;
        background-position: center;
      }

      .btn {
        button {
          width: 100%;
        }
      }
    }

    .foot {
      width: 100%;
      position: fixed;
      text-align: center;
      bottom: 0;

      .biean {
        margin: 0.395832rem auto;
        font-size: 0.554165rem;
        color: #606266;
      }

      .biean:hover {
        color: #00ffff;
      }
    }
  }
</style>
