import fetch from "@/api/fetch"
// 登录
export function getPublicKey(username){
	const data={username}
	return fetch({
		url:'/apg/login/user/getPublicKey',
		method:'post',
		data
	})
}

export function userLogin(username,password){
	const data={username,password}
	return fetch({
		url:'/apg/login/userLogin',
		method:'post',
		data
	})
}